import React, { useEffect, useState } from "react";
import { metamask } from "../connectors/connectors";
import { useWeb3React } from "@web3-react/core";

function Web3Wallet() {
  const { account, provider, connector } = useWeb3React();
  const [sign, setSign] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [web3Address, setWeb3Address] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [customText, setCustomText] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("Connect To MetaMask");

  useEffect(() => {
    const urlparams = new URLSearchParams(document.location.search);
    if (urlparams.get("sign")) {
      setButtonText("Sign from Metamask");
    }
    setSign(Boolean(urlparams.get("sign")));
    setWeb3Address(urlparams.get("address") as string);
    setMessage(urlparams.get("message") as string);
  }, [document.location.search]);

  useEffect(() => {
    const personalSignReq = async () => {
      const sig = await (provider as any).provider
        .request({
          method: "personal_sign",
          params: [message, account],
        })
        .catch((err: any) => {
          console.error("error at personal sign: ", err);
        });
      setCustomText("User has signed request");
      if (sig) {
        window.location.href = `dchatweb3wallet://dchat-web3-wallet.dweb/?sign=${sig}&address=${account}`;
      }
    };
    if (sign && provider) {
      setCustomText("Sending sign request to metamask...");
      setTimeout(() => {
        personalSignReq();
      }, 1000);
    }
  }, [sign, provider]);

  useEffect(() => {
    if (account && !sign) {
      setCustomText("Connected to Metamask");
      setTimeout(() => {
        window.location.href = `dchatweb3wallet://dchat-web3-wallet.dweb/?address=${account}&wallet=metamask`;
      }, 1000);
    }
  }, [account, sign]);

  const onMetaMask = () => {
    metamask
      .activate()
      .then(() => {
        setIsConnected(true);
      })
      .catch((err) => console.error("metamask error: ", err));
  };
  const onDisconnect = async () => {
    if (connector?.deactivate) {
      await connector.deactivate();
      localStorage.clear();
    } else {
      connector.resetState();
      localStorage.clear();
    }
    setIsConnected(false);
  };

  return (
    <div className="full-screen-flow">
      <div className="step">
        <div className="inner">
          <div className="module-dchat-logo" />
          <h1 className="header">Welcome to DecentraChat</h1>
          <div>
            {isConnected ? (
              <div className="wallet-info">
                <div className="dchat-wallet-info-text">{customText}</div>
                <div className="dchat-wallet-info-text">Address: {account}</div>
                <button className="dchat-wallet-button" onClick={onDisconnect}>
                  Disconnect Metamask
                  <div className="module-metamask-logo" />
                </button>
              </div>
            ) : (
              <button className="dchat-wallet-button" onClick={onMetaMask}>
                {buttonText}
                <div className="module-metamask-logo" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web3Wallet;
