import React from 'react';
import './App.scss';

import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { metamask, metamaskHooks } from './connectors/connectors';
import type { Connector } from '@web3-react/types';
import Web3Wallet from './components/Web3Wallet';


function App() {
  const connectors: [Connector, Web3ReactHooks][]  = [
    [metamask, metamaskHooks]
  ];
  return (
    <div className="App">
      <Web3ReactProvider connectors={connectors}>
        <Web3Wallet />
      </Web3ReactProvider>
    </div>
  );
}

export default App;
